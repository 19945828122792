<template>
  <div class="h-100">
    <div class="flex-cloumn h-100">
      <div class="tab">
        <!-- tab栏 -->
        <div
          class="tab-item"
          v-for="item in tabMenu"
          :key="item.id"
          :class="curTab === item.id ? 'tab-item-active' : ''"
          @click="curTab = item.id"
        >
          {{ item.name }}
        </div>
      </div>
      <!-- 筛选表单 -->
      <div v-if="curTab === 3">
        <div class="select-wrap">
          <div>
            <span>发放日期：</span>
            <el-date-picker
              v-model="tableFilter1.date"
              type="daterange"
              size="mini"
              value-format="yyyy-MM-dd"
              range-separator="-"
              style="width: 280px"
              start-placeholder="开始日期"
              end-placeholder="结束日期"
            >
            </el-date-picker>
          </div>
        </div>
        <div class="flex-row space-between">
          <div>
            <el-select
              v-model="tableFilter1.sellStatus"
              class="select-item"
              style="width: 100px"
              size="mini"
              placeholder="付款方式"
            >
              <el-option
                v-for="(option, index) in selectArr.pay"
                :key="index"
                :label="option.label"
                :value="option.value"
              ></el-option>
            </el-select>
            <span style="margin-left: 10px">价格区间：</span>
            <el-input
              :disabled="tableFilter1.buyAttributes === null"
              v-model="tableFilter1.startPrice"
              style="width: 130px"
              size="mini"
              placeholder="请选择付款方式"
            ></el-input>
            -
            <el-input
              :disabled="tableFilter1.buyAttributes === null"
              v-model="tableFilter1.endPrice"
              style="width: 130px"
              size="mini"
              placeholder="请选择付款方式"
            ></el-input>
          </div>
          <div>
            <el-select
              v-model="tableFilter1.sort"
              class="select-item"
              style="width: 100px"
              size="mini"
              placeholder="排序"
            >
              <el-option
                v-for="(option, index) in selectArr.sort"
                :key="index"
                :label="option.label"
                :value="option.value"
              ></el-option>
            </el-select>
            <el-select
              disabled
              class="select-item"
              style="width: 100px"
              size="mini"
              placeholder="批量管理"
            >
              <el-option
                v-for="(option, index) in list"
                :key="index"
                :label="option.name"
                :value="option.id"
              ></el-option>
            </el-select>
          </div>
        </div>
      </div>
      <div v-if="curTab === 1">
        <div class="select-wrap">
          <div>
            <!-- <span>订单日期：</span> -->
            <el-select
              class="select-item"
              style="width: 100px"
              size="mini"
              placeholder="类型"
              v-model="tableFilter1.inSideType"
            >
             <el-option
                v-for="(option, index) in selectArr.scriptAbout"
                :key="index"
                :label="option.label"
                :value="option.value"
              ></el-option>
            </el-select>
            <el-date-picker
              v-model="tableFilter1.date"
              type="daterange"
              size="mini"
              value-format="yyyy-MM-dd"
              range-separator="-"
              style="width: 280px"
              start-placeholder="开始日期"
              end-placeholder="结束日期"
            >
            </el-date-picker>
          </div>
        </div>
        <div class="flex-row space-between">
          <div>
            <el-select
              v-model="tableFilter1.payWay"
              class="select-item"
              style="width: 100px"
              size="mini"
              placeholder="付款方式"
            >
              <el-option
                v-for="(option, index) in selectArr.pay"
                :key="index"
                :label="option.label"
                :value="option.value"
              ></el-option>
            </el-select>
            <span style="margin-left: 10px">价格区间：</span>
            <el-input
              :disabled="tableFilter1.payWay === null"
              v-model="tableFilter1.beginPrice"
              style="width: 130px"
              size="mini"
              placeholder="请选择付款方式"
            ></el-input>
            -
            <el-input
              :disabled="tableFilter1.payWay === null"
              v-model="tableFilter1.overPrice"
              style="width: 130px"
              size="mini"
              placeholder="请选择付款方式"
            ></el-input>
          </div>
          <!-- <div>
            <el-select
              v-model="tableFilter1.sort"
              class="select-item"
              style="width: 100px"
              size="mini"
              placeholder="排序"
            >
              <el-option
                v-for="(option, index) in selectArr.sort"
                :key="index"
                :label="option.label"
                :value="option.value"
              ></el-option>
            </el-select>
            <el-select
              disabled
              class="select-item"
              style="width: 100px"
              size="mini"
              placeholder="批量管理"
            >
              <el-option
                v-for="(option, index) in list"
                :key="index"
                :label="option.name"
                :value="option.id"
              ></el-option>
            </el-select>
          </div> -->
        </div>
      </div>
      <div v-if="curTab === 2">
        <div class="select-wrap">
          <div>
            <span>订单日期：</span>
            <el-date-picker
              v-model="tableFilter2.date"
              type="daterange"
              size="mini"
              value-format="yyyy-MM-dd"
              range-separator="-"
              style="width: 280px"
              start-placeholder="开始日期"
              end-placeholder="结束日期"
            >
            </el-date-picker>
          </div>
        </div>
        <div class="flex-row space-between">
          <div>
            <el-select
              v-model="tableFilter2.payWay"
              class="select-item"
              style="width: 100px"
              size="mini"
              placeholder="付款方式"
            >
              <el-option
                v-for="(option, index) in selectArr.pay"
                :key="index"
                :label="option.label"
                :value="option.value"
              ></el-option>
            </el-select>
            <span style="margin-left: 10px">价格区间：</span>
            <el-input
              :disabled="tableFilter2.payWay === null"
              v-model="tableFilter2.beginPrice"
              style="width: 130px"
              size="mini"
              placeholder="请选择付款方式"
            ></el-input>
            -
            <el-input
              :disabled="tableFilter2.payWay === null"
              v-model="tableFilter2.overPrice"
              style="width: 130px"
              size="mini"
              placeholder="请选择付款方式"
            ></el-input>
          </div>
          <!-- <div>
            <el-select
              v-model="tableFilter1.sort"
              class="select-item"
              style="width: 100px"
              size="mini"
              placeholder="排序"
            >
              <el-option
                v-for="(option, index) in selectArr.sort"
                :key="index"
                :label="option.label"
                :value="option.value"
              ></el-option>
            </el-select>
            <el-select
              disabled
              class="select-item"
              style="width: 100px"
              size="mini"
              placeholder="批量管理"
            >
              <el-option
                v-for="(option, index) in list"
                :key="index"
                :label="option.name"
                :value="option.id"
              ></el-option>
            </el-select>
          </div> -->
        </div>
      </div>
      <div class="row2 flex-1 overflow-y shadow">
        <el-card class="row2-card" v-loading="loading">
          <!-- 表头 -->
          <div class="flex-row table-header" style="padding-left: 60px">
            <div style="width: 156px; text-align: left">订单号</div>
            <div style="width: 160px">用户名称</div>
            <div style="width: 290px" class="relative-left-10">项目名称</div>
            <div style="width: 110px">价格</div>
            <div style="width: 130px" class="relative-left-10">购买日期</div>
          </div>
          <!-- 行数据 -->
          <div
            class="item"
            v-for="(item, i) in list"
            :key="i"
            :style="{
              background: i % 2 !== 0 ? '#fff' : 'rgba(250,249,255,1)',
            }"
          >
            <el-checkbox></el-checkbox>
            <!-- 商品序号 -->
            <div class="item-index">
              {{ i + 1 }}
            </div>
            <div class="flex-row space-around flex-1">
              <div class="item-title shenglue-1" style="width: 20%">
                {{ item.id }}
              </div>
              <div class="item-title shenglue-1" style="width: 18%">
                {{ item.nickname }}
              </div>
              <div class="item-title" style="width: 20%; text-align: center;white-space:nowrap;overflow: visible">
                {{ item.productName }}
              </div>
              <div class="item-date shenglue-1" style="width: 15%; text-align: center">
                {{ item.payPrice }}{{ payArr[item.payWay - 1] }}
              </div>
              <div class="item-date shenglue-1">
                {{ item.payTime }}
              </div>
            </div>
          </div>
        </el-card>
      </div>
    </div>
    <div class="row3" v-if="curTab === 1">
      <el-pagination
        :total="total"
        :current-page.sync="tableFilter1.currPage"
        :page-size.sync="tableFilter1.limit"
        layout="prev, pager, next"
      ></el-pagination>
    </div>
    <div class="row3" v-if="curTab === 2">
      <el-pagination
        :total="total"
        :current-page.sync="tableFilter2.currPage"
        :page-size.sync="tableFilter2.limit"
        layout="prev, pager, next"
      ></el-pagination>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    // 筛选选择框集合
    this.selectArr = {
      pay: [
        { label: "全部", value: null},
        { label: "人民币", value: 1 },
        { label: "点券", value: 2 },
        { label: "金币", value: 3 },
      ],
      scriptAbout: [
        { label: "全部", value: null },
        { label: "剧本", value: 0 },
        { label: "剧本内容", value: 1 },
        { label: "线索", value: 2 },
      ]
    };
    this.payArr = ["人民币", "点券", "金币"];
    return {
      loading: false,
      // tab栏
      curTab: 2,
      couponDetail: {},
      tabMenu: [
        { name: "商城商品", id: 2 },
        { name: "剧本相关", id: 1 },
        // { name: "补偿福利", id: 3 },
      ],
      value2: "",
      tableFilter1: {
        inSideType: null,
        date: [],
        payWay: null,
        orderEndTime: null,
        orderStartTime: null,
        overPrice: null,
        beginPrice: null,
        currPage: 1,
        limit: 50
      },
      tableFilter2: {
        date: [],
        payWay: null,
        orderEndTime: null,
        orderStartTime: null,
        overPrice: null,
        beginPrice: null,
        currPage: 1,
        limit: 50
      },
      list: [],
      total: 100,
    };
  },
  created() {
    this.getOrderList();
  },
  methods: {
    async getOrderList() {
      this.loading = true;
      const data = await this.$api.shop
        .getOrderList(this.dealParams(this[`tableFilter${this.curTab}`]))
        .finally(() => {
          this.loading = false;
        });
        this.curTab === 2 && data.data.forEach(item => {
          item.productName = item.productName.substring(1,item.productName.length-1)
        })
      this.list = data.data;
      this.total = data.count;
    },
    dealParams(obj) {
      const params = JSON.parse(JSON.stringify(obj));
      if (params.date && params.date.length) {
        params.orderStartTime = params.date[0];
        params.orderEndTime = params.date[1];
      }
      params.shopType = this.curTab
      delete params.date;
      return params;
    },
  },
  watch: {
    tableFilter1: {
      handler() {
        this.getOrderList();
      },
      deep: true,
    },
    tableFilter2: {
      handler() {
        this.getOrderList();
      },
      deep: true,
    },
    curTab() {
      this.getOrderList();
    },
  },
};
</script>
<style lang="scss" scoped>
@import "./common/style/shop.scss";
@import "./common/style/form.scss";
.item-date {
  text-align: left;
}
</style>